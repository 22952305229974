import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic1.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic2.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic3.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic4.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic5.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic6.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic7.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic8.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic9.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic10.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic11.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic12.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic13.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic14.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic15.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic16.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic17.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic18.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic19.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic20.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic21.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-1/pic22.jpeg",
];

export default function InternationalYogaDay2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">International Yoga Day 2023</h1>

        <p>
          <strong>A journey of the self, to the self, through the self.</strong>
          <br />
          <br />
          To acknowledge the union of mind, body and soul and discover the sense
          of oneness with self, the world and the nature, International Yoga Day
          was celebrated with great fervor by the students and teachers of
          Chinmaya Vidyalaya, New Delhi. Students performed various Asanas –
          Tadasana (Palm Tree Posture), Trikonasana (Triangle Posture),
          Parvatasana(Forward bending posture) followed by Pranayama (breathing
          exercises) and Om chanting . The significance of the day was well-
          articulated coupled with befitting demonstration of Yoga Asanas by the
          students. Principal, Ms Archana Soni encouraged the students to make
          Yoga a way of life.
          <br />
          <br />
          The students of Primary Department performed Surya Namaskar using a
          video based Powerpoint Presentation that also guided them about its
          benefits .The little learners of Pre-Primary practiced various sitting
          and standing asanas under the able guidance of their parents and they
          were briefed about the significance of the same asanas.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
